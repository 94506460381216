function nWin() {
  var w = window.open();
  var html = $("body").html();

  $(w.document.body).html(html);
  w.print();
}

$("#print").click(function () {
  window.print();
});
