+$(".business-model__btn").on("click", function () {
  specialTabs($(this), ".business-model");
});

$(".risk-management__btn").on("click", function () {
  specialTabs($(this), ".risk-management");
});

function specialTabs(el, className) {
  const id = el.data("id");
  if (!el.hasClass("active")) {
    $(className + "__btn").removeClass("active");
    $(className + "__svg").removeClass("active");
    $(className + "__content").removeClass("active");

    $(el).addClass("active");
    $(className + "__svg[data-id=" + id + "]").addClass("active");
    $(className + "__content[data-id=" + id + "]").addClass("active");
  }
}
