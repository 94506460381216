var undefined;
const CLIPBOARD = "clipboard";
const ATTACHMENT = "attachment";
const TAGS_REGEX = /(<([^>]+)>)/gi;

function isEmpty() {
  return (
    Object.keys(getItems("clipboard")).length === 0 &&
    Object.keys(getItems("attachment")).length === 0 &&
    Object.keys(getItems("note")).length === 0
  );
}

function addItem(item, group) {
  var clipboard = getItems(group);
  if (clipboard[item.slug] === undefined) {
    clipboard[item.slug] = item;
  }
  setItems(clipboard, group);
}
function removeItem(slug, group) {
  var clipboard = getItems(group);
  if (clipboard[slug] != undefined) {
    delete clipboard[slug];
  }
  setItems(clipboard, group);
}
function setItems(items, group) {
  if (items.undefined !== undefined) {
    delete items.undefined;
  }
  localStorage.setItem(group, JSON.stringify(items));
}
function getItems(group) {
  var json = localStorage.getItem(group);
  if (json) {
    return JSON.parse(json);
  }
  return {};
}
function clearItems(group) {
  localStorage.removeItem(group);
}

$.fn.refreshClipboard = function (group) {
  var ul = $(this).find("[data-" + group + "-list]");
  var inputs = $(this).find("[data-" + group + "-inputs]");
  ul.html("");
  inputs.html("");
  var items = getItems(group);
  var length = 0;

  for (var i in items) {
    var li = $("<li>");
    var a = null;
            
    if(items[i].source){
        a = $("<div>");
        var tt = items[i].url ? $("<a>") : $("<span>");
        if(items[i].url){
            tt.attr('href', items[i].url);
        }
        tt.html(items[i].source);
        a.append(tt);
        a.append('<br/>');
        a.append(items[i].title.replace(TAGS_REGEX, ''));
    }else{
        a = items[i].url ? $("<a>") : $("<div>");
        a.html(items[i].title.replace(TAGS_REGEX, ''));
    }
    if(items[i].url){
          a.attr("href", items[i].url)
          .attr("target", "_blank");
    }
    var edit = $("<button>")
      .attr("type", "button")
      .html($("html").attr("lang") == "pl" ? "Edytuj" : "Edit")
      .attr("data-edit-" + group, items[i].slug);
      
    var remove = $("<button>")
      .attr("type", "button")
      .html($("html").attr("lang") == "pl" ? "Usuń" : "Remove")
      .attr("data-remove-" + group, items[i].slug);
    li.append(a);
    li.append(" ");
    if(items[i].editable){
        li.append(edit);
    }
    li.append(remove);
    var inp = $("<input>")
      .attr("type", "hidden")
      .attr("name", "download-" + group + "[]")
      .val(items[i].slug);
    inputs.append(inp);

    ul.append(li);
    length++;
  }
  if (length > 0) {
    $(this)
      .find("[data-" + group + "-container]")
      .show();
  } else {
    $(this)
      .find("[data-" + group + "-container]")
      .hide();
  }

  if (isEmpty()) {
    $(this).find("[data-submit]").attr("disabled", "disabled");
    $(this).find("[data-container-empty]").show();
  } else {
    $(this).find("[data-submit]").removeAttr("disabled");
    $(this).find("[data-container-empty]").hide();
  }
};

$.fn.removeClipboard = function (slug, group) {
  removeItem(slug, group);
  $(this).refreshClipboard(group);
};

$.fn.clearClipboard = function (group) {
  clearItems(group);
  $(this).refreshClipboard(group);
};

$.fn.generateClipboard = function (group) {
  $(this).refreshClipboard(group);
  $("[data-" + group + "-download]").trigger("submit");
};

$.fn.clipboard = function (group) {
  var self = $(this);

  $(this).refreshClipboard(group);
  self.on("click", "[data-generate]", function (e) {
    $(self).generateClipboard(group);
    return false;
  });

  self.on("click", "[data-remove-" + group + "]", function () {
    var slug = $(this).data("remove-" + group);
    $(self).removeClipboard(slug, group);
    return false;
  });
  self.on("click", "[data-clear-" + group + "]", function () {
    $(self).clearClipboard(group);
    return false;
  });
};

$(function () {
  $("#clipboard").clipboard("clipboard");
  $("#clipboard").clipboard("attachment");
  $("#note-list").clipboard("note");
  
  $('#note-field').val('');

  $("[data-add-clipboard]").on("click", function (e) {
    e.preventDefault();
    var target = $(this).data("clipboard-target");
    var item = $(this).data("add-clipboard");
    addItem(item, "clipboard");
    $(target).refreshClipboard("clipboard");
    $(target).modal("show");
  });
  
  $("[data-add-attachment]").on("click", function (e) {
    e.preventDefault();
    var target = $(this).data("attachment-target");
    var item = $(this).data("add-attachment");
    addItem(item, "attachment");
    $(target).refreshClipboard("attachment");
    $(target).modal("show");
  });
  
  $("[data-add-note]").on("click", function (e) {
    e.preventDefault();
    var target = $(this).data("note-target");
    var slug = 'note-'+(Object.keys(getItems("note")).length);
    if($('#note-field').data('slug')){
        slug = $('#note-field').data('slug');
        removeItem(slug, 'note')
    }
    var source = $(this).data('add-note').source;
    var url = $(this).data('add-note').url;
    var item = {
        title: $('#note-field').val().replace(TAGS_REGEX, ''),
        slug: slug,
        url: url,
        source: source,
        editable: true,
    };
    addItem(item, "note");
    $(target).refreshClipboard("note");
    $(target).modal("show");

    $('#note-field').removeData('slug');
    $('#note-field').val('');
  });
  $(document).on("click", "[data-edit-note]", function (e) {
    e.preventDefault();
    var slug = $(this).data("edit-note");
    $('#note-field').data('slug', slug);
    
    var notes = getItems('note');
    $('#note-field').val(notes[slug].title);
      
    $('#clipboard').modal("hide");
    $('#note').modal("show");
  });
});
