import THREELib from "three-js";

const THREE = THREELib(["SVGLoader"]);
const svgList = document.querySelectorAll(".inline-svg");

for (let i = 0; i < svgList.length; i++) {
  let loader = new THREE.SVGLoader();
  let id = svgList[i].getAttribute("data-id");
  let name = svgList[i].getAttribute("data-name");
  loader.load(
    "/assets/svg/" + name + ".svg",
    function (data) {
      var s = new XMLSerializer();
      document.querySelector(
        ".inline-svg[data-id='" + id + "']"
      ).innerHTML = s.serializeToString(data);
    },
    function (xhr) {},
    function (error) {
      console.log("An error happened");
    }
  );
}
