$(".hamburger").on("click", function () {
  if (!$(this).hasClass("hamburger--active")) {
    $(this).addClass("hamburger--active");
    $(".menu .inner-menu").addClass("active");
    $("html").css("overflow", "hidden");
  } else {
    $(this).removeClass("hamburger--active");
    $(".menu .inner-menu").removeClass("active");
    $("html").css("overflow", "visible");
  }
});

$(".menu__options").on("click", function () {
  if (!$(this).hasClass("active")) {
    $(this).addClass("active");
    $(".menu__short").slideDown(400);
  } else {
    $(this).removeClass("active");
    $(".menu__short").slideUp(400);
  }
});

$(".nav ul li button").on("click", function () {
  if (!$(this).hasClass("active")) {
    $(".nav ul li button").removeClass("active");
    $(".nav ul li ul").slideUp();
    $(this).addClass("active");
    $(this).next("ul").slideDown(300);
  } else {
    $(this).removeClass("active");
    $(".nav ul li ul").slideUp();
  }
});

$(document).ready(function () {
  $(".inner-menu").css("display", "flex");
});

//functions
$("#addtobookmark").click(function () {
  if (window.sidebar && window.sidebar.addPanel) {
    // Mozilla Firefox Bookmark
    window.sidebar.addPanel(document.title, window.location.href, "");
  } else if (window.external && "AddFavorite" in window.external) {
    // IE Favorite
    window.external.AddFavorite(location.href, document.title);
  } else if (window.opera && window.print) {
    // Opera Hotlist
    this.title = document.title;
    return true;
  } else {
    // webkit - safari/chrome
    alert(
      "Press " +
        (navigator.userAgent.toLowerCase().indexOf("mac") != -1
          ? "Command/Cmd"
          : "CTRL") +
        " + D aby dodać do zakładek"
    );
  }
});

$("#font-size").on("click", function () {
  console.log("asds");
  if (!$("html").hasClass("font-size")) {
    $("html").addClass("font-size");
    $(this).addClass("active");
    localStorage.setItem("font-size", "true");
  } else {
    $("html").removeClass("font-size");
    $(this).removeClass("active");
    localStorage.setItem("font-size", "false");
  }
});

if (localStorage.getItem("font-size") == "true") {
  $("html").addClass("font-size");
  $("#font-size").addClass("active");
} else {
  $("html").removeClass("font-size");
  $("#font-size").removeClass("active");
}

$("#contrast").on("click", function () {
  if (!$("html").hasClass("hc")) {
    $("html").addClass("hc");
    $(this).addClass("active");
    localStorage.setItem("hc", "true");
  } else {
    $("html").removeClass("hc");
    $(this).removeClass("active");
    localStorage.setItem("hc", "false");
  }
});

if (localStorage.getItem("hc") == "true") {
  $("html").addClass("hc");
  $("#contrast").addClass("active");
} else {
  $("html").removeClass("hc");
  $("#contrast").removeClass("active");
}
