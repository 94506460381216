$("#search").on("click", function () {
  if (!$(this).hasClass("active")) {
    $(this).addClass("active");
    $("html").css("overflow", "hidden");
    $(".search-box").css("display", "flex").hide().fadeIn(400);
  } else {
    $(this).removeClass("active");
    $(".search-box").fadeOut(200);
    $("html").css("overflow", "visible");
  }
});

$("#search-close").on("click", function () {
  $("#search").removeClass("active");
  $(".search-box").fadeOut(200);
  $("html").css("overflow", "visible");
});
