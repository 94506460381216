import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

let home = $(".home__carousel");
home.owlCarousel({
  loop: true,
  nav: false,
  lazyLoad: true,
  dots: false,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  autoplay: true,
  autoplayTimeout: 6000,
  smartSpeed: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
  },
});
$(".home__slider-nav--prev").on("click", function () {
  home.trigger("prev.owl.carousel");
});

$(".home__slider-nav--next").on("click", function () {
  home.trigger("next.owl.carousel");
});
