//auto scroll and open
let url = window.location.href.split("#")[1];
if (typeof url != "undefined") {
  let item = url;
  // let targetId = $('.collapse .gri-link.'+item).closest('.collapse').attr('id');
  let number = url.split("-")[1];
  let targetId = "collapse-" + number;

  if ($("#" + targetId).hasClass("show")) {
  } else {
    $("#" + targetId).collapse("show");
    $(window).outerWidth(true) < 1200 ? scroll(item, 80) : scroll(item, 0);
  }
}

function scroll(item, shift) {
  $("html, body").animate(
    {
      scrollTop: $("#" + item).offset().top - shift,
    },
    1000
  );
}
