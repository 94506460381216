$(".gri-marker__btn").on("click", function () {
  if (!$(this).hasClass("active")) {
    $(this).addClass("active");
    $(".gri-marker").addClass("active");
    $(".gri-marker__list").slideDown(300);
  } else {
    $(this).removeClass("active");
    $(".gri-marker").removeClass("active");
    $(".gri-marker__list").slideUp(300);
  }
});
