$(".changeToChart").on("click", function () {
  $(this).parent().parent().children(".item__chart").slideToggle();
  $(this).parent().parent().children(".item__table").slideToggle();
  $(this).toggleClass("active");
  $(this).parent().children(".changeToTable").removeClass("active");
});

$(".changeToTable").on("click", function () {
  $(this).parent().parent().children(".item__chart").slideToggle();
  $(this).parent().parent().children(".item__table").slideToggle();

  $(this).toggleClass("active");
  $(this).parent().children(".changeToChart").removeClass("active");
});
