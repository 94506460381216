$(document).ready(function () {
  $("form.questionnaire").on("submit", function (e) {
    console.log($(this).attr("action"));
    console.log($(this).serialize());
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      dataType: "json",
    }).done(function () {
      $("#questionnaire-sent").modal();
    });
    return false;
  });
});
