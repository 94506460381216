$(".stakeholders__svg").on("click", ".text", function () {
  const id = $(this).attr("id").split("-")[1];
  $(".text").removeClass("active");
  $(".selection").removeClass("active");
  $(".circle").removeClass("active");
  $(".stakeholders__icon").removeClass("active");
  $(".stakeholders__content").removeClass("active");
  $(this).addClass("active");
  $("#selection-" + id).addClass("active");
  $("#circle-" + id).addClass("active");
  $(".stakeholders__icon[data-id=" + id + "]").addClass("active");
  $(".stakeholders__content[data-id=" + id + "]").addClass("active");
});

$(".stakeholders__svg").on("click", ".selection", function () {
  const id = $(this).attr("id").split("-")[1];
  $(".text").removeClass("active");
  $(".selection").removeClass("active");
  $(".circle").removeClass("active");
  $(".stakeholders__icon").removeClass("active");
  $(".stakeholders__content").removeClass("active");
  $(this).addClass("active");
  $("#text-" + id).addClass("active");
  $("#circle-" + id).addClass("active");
  $(".stakeholders__icon[data-id=" + id + "]").addClass("active");
  $(".stakeholders__content[data-id=" + id + "]").addClass("active");
});
