$(".scrollTo").on("click", function (e) {
  e.preventDefault();
  var id = $(this).attr("data-href");
  setTimeout(function () {
    height = $("#" + id)
      .slideDown()
      .offset().top;
    $("body, html").animate({ scrollTop: height }, 800, "swing");
  }, 800);
});

// const url = window.location.href.split("#")[1];
// if (url != "undefined") {
//   $("html, body").animate(
//     {
//       scrollTop: $("#" + url).offset().top - 180,
//     },
//     1000
//   );
// }
