import Cookies from "js-cookie";

function isIE() {
  const ua = navigator.userAgent;
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}

if (isIE()) {
  if (Cookies.get("ie") !== "yes") {
    $(".mask").fadeIn(200);
    $(".ie-popup").fadeIn(300);
  }
}

$(".ie-popup__close").on("click", function () {
  $(".ie-popup").fadeOut(200);
  $(".mask").fadeOut(300);
  Cookies.set("ie", "yes", { expires: 1 });
});
